import { useRef, useEffect, forwardRef } from "react";
import withAnimateTrigger from "hoc/withAnimateTrigger";
import HTMLReactParser from "html-react-parser";
import useChartHelper from "hooks/useChartHelper";
import { calcWaypoints } from "./helpers";
import "./chart.scss";

const Chart = forwardRef( function ({ title, columns, ...props }, ref ) {   

   let chart_xy = {};
   
   const canvas_ref = useRef();
   const ctx_ref = useRef();

   const columns_center_x = useRef([]);
   const was_animated_ref = useRef( false );
   const data_ref = useRef( props.data || [])

   const { max_value, scale, scale_divider } = useChartHelper( props.data );

	const calculateChartXY = () => {
      const canvas = canvas_ref.current;

		const x_start = 55;
		const y_start = 30;
		const y_end = canvas.height - 50;
		const chart_height = y_end - y_start;

		chart_xy = {
			x_start,
			y_start,
			y_end,
			chart_height,
         canvas_h: canvas.height,
         canvas_w: canvas.width
		}
	}

	const calculateColumnsCenterX = () => {
		
      const canvas = canvas_ref.current;
		const { x_start } = chart_xy;
		const padding = 55;

		let column_width = (( canvas.width - x_start - padding * 2 ) / ( columns.length - 1 ));
		const result = [ x_start + padding ];

		for ( let i = 1; i < columns.length; i++ ) {
			result.push( Math.floor( result[ i - 1 ] + column_width ));
		}

		columns_center_x.current = result;
	}

   const calculateValuesY = () => {

		const { y_start, chart_height } = chart_xy;

      data_ref.current = data_ref.current.map( item  => {			
			return {
				...item,
            values_y: item.values.map( value => {
               const percent = Math.floor( 100 * value / max_value );
               const y = chart_height * ( 100 - percent ) / 100 + y_start;
               return y;
            })
			}
		})
   }

	const drawColumns = () => {

      const ctx = ctx_ref.current;
		ctx.font = "20px Montserrat, sans-serif";
		ctx.textAlign = "center";
		ctx.fillStyle = "#646464";

      const { canvas_h, y_end } = chart_xy;

		columns.forEach(( year, i ) => {
			const x = columns_center_x.current[i];

         // vertical line
         ctx.beginPath();
         ctx.lineWidth = 1;
         ctx.strokeStyle = "#E8E8E8";
         ctx.moveTo( x, 0 );
         ctx.lineTo( x, y_end );
         ctx.stroke();

         // column name
         ctx.fillText( year, x, canvas_h - 15 );
		})
	}

	const drawScale = () => {

      const ctx = ctx_ref.current;
		const { canvas_w, x_start, y_start, y_end, chart_height } = chart_xy;
		const height_step_y = chart_height / scale_divider;

      // zero level line
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#000";
      ctx.beginPath();
      ctx.moveTo( x_start, y_end );
      ctx.lineTo( canvas_w, y_end );
      ctx.stroke();

		scale.forEach(( value, i ) => {

			const y = i * height_step_y + y_start;
         ctx.strokeStyle = "#E8E8E8";

			ctx.beginPath();
			ctx.moveTo( x_start + 10, y );
			ctx.lineTo( canvas_w, y );
			ctx.stroke();

			ctx.font = "13px Montserrat, sans-serif";
			ctx.textAlign = "center";
			ctx.fillStyle = "#646464";
			ctx.fillText( value, x_start / 2, y + 5 );
		});
	}

   const drawOnePoint = opt => {

      const ctx = ctx_ref.current;
      const { x, y, color, value } = opt;

      // point circle
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.arc( x, y, 7, 0, 2 * Math.PI );
      ctx.fill();
      
      // value
      ctx.fillText( Intl.NumberFormat().format( value ), x, y - 16 );
   }

   const drawAnimatedLines = () => {

      const ctx = ctx_ref.current;

      function drawDataItemLine( item_index ) {
         
         const { color, values_y } = data_ref.current[ item_index ];
         const waypoints = calcWaypoints( values_y.map(( y, i ) => ({
            x: columns_center_x.current[i], y
         })));

         let t = 1;         
         animate();

         function animate() {
            
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.lineWidth = 4;
            ctx.moveTo(waypoints[t - 1].x, waypoints[t - 1].y);
            ctx.lineTo(waypoints[t].x, waypoints[t].y);
            ctx.stroke();
            
            t++;

            if ( t < waypoints.length - 1) {
               setTimeout( animate, 5 );
            }
         }
      }

      data_ref.current.forEach(( d, i ) => drawDataItemLine( i ))
   }

   const drawAnimatedPoints = () => {

      function drawDataItemPoint( item_index ) {
         const { color, values, values_y } = data_ref.current[ item_index ];

         let i = 0;
         function drawPoint() {
            drawOnePoint({
               color,
               value: values[i],
               x: columns_center_x.current[i],
               y: values_y[i]
            })

            i++;
            if ( i < values.length ) {
               setTimeout( drawPoint, 600 );
            }
         }

         drawPoint(0);
      }

      data_ref.current.forEach(( v, i ) => drawDataItemPoint( i ))
   }

	const drawLines = () => {

      const ctx = ctx_ref.current;

		data_ref.current.forEach(({ color, values_y }) => {
         ctx.beginPath();
         ctx.strokeStyle = color || "#000";
         ctx.lineWidth = 4;
         values_y.forEach(( y , i ) => {
            ctx.lineTo( columns_center_x.current[i], y )
            ctx.stroke();
         });
      })
	}

	const drawPoints = () => {

		data_ref.current.forEach(({ color = "#000", values, values_y }) => {
         values.forEach(( value, i ) => {
            drawOnePoint({
               color,
               value,
               x: columns_center_x.current[i],
               y: values_y[i]
            })
         })
		})
	}

	const drawChart = () => {
	
		ctx_ref.current.clearRect( 0, 0, canvas_ref.current.width, canvas_ref.current.height );

      calculateChartXY();
      calculateColumnsCenterX();
      calculateValuesY();

      drawColumns();
		drawScale();

      was_animated_ref.current && drawLines();
		was_animated_ref.current && drawPoints();
	}

   useEffect(() => {
		ctx_ref.current = canvas_ref.current.getContext( "2d" );
   }, [])

	useEffect(() => {
      
		function setCanvasSize() {
         const canvas = canvas_ref.current;
			const parent = canvas?.parentElement;
			if ( !canvas || !parent ) return;

			canvas.width = parent.offsetWidth;
			canvas.height = parent.offsetHeight;

         drawChart();
		}

		setCanvasSize();

      window.addEventListener( "resize", setCanvasSize );
		return () => window.removeEventListener( "resize", setCanvasSize )
	}, []);

   useEffect(() => {
      
      if ( !props.animate || was_animated_ref.current ) return;
      was_animated_ref.current = true;

      drawAnimatedLines();
      drawAnimatedPoints();

   }, [ props.animate ])

	return (
      <div className="chart">
         { title && <h3> { HTMLReactParser( title )} </h3> }

         <div className="chart-legend">
            { Array.isArray( props.data ) && props.data?.map(({ color, label }) => color && label ? (
               <div key={ label }>
                  <div style={{ background: color }}/>
                  { label }
               </div>
            ) : null )}
         </div>
         
         <div ref={ ref } style={{ position: "relative", aspectRatio: 16/9 }}>
            <canvas
               ref={ canvas_ref }
               style={{ position: "absolute" }}
            />
         </div>
      </div>
	)
})

export default withAnimateTrigger( Chart )