import { useMemo } from "react";

const getChatDataMaxValue = data => data.reduce(( max, current ) => {
	const value = current.value || Math.max(...current.values );
	return max > value ? max : value;
}, 0 );

export default function useChartHelper( data ) {

   const max_value = useMemo(() => {
		
		if ( !data ) return;

		const max_data_value = getChatDataMaxValue( data );
		const max_data_value_str = "" + max_data_value.toFixed();
      const slice_index = max_data_value >= 10000 ? 2 : 1;
		const max_value = ( +( max_data_value_str ).slice( 0, slice_index ) + 1 ) + "0".repeat( max_data_value_str.length - slice_index );

		return +max_value;
	}, [ data ])

   const scale_divider = max_value % 6 === 0 ? 6 : 5;

	const scale = useMemo(() => {

		if ( !max_value ) return;

		const scale_step = max_value / scale_divider;
		const result = [ max_value ];

		for ( let i = max_value; i > scale_step; ) {
			i -= scale_step;
			result.push( i );
		}

		return result;

	}, [ max_value ]);

   return { max_value, scale, scale_divider }
}