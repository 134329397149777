import Axios from "axios";

const default_params = window.config.DEFAULT_PARAMS || {};

const API = Axios.create({ 
	baseURL: window.config.API_URL
});

API.interceptors.response.use( resp => resp.data );

API.interceptors.request.use( config => {

	const c = config || {};

	if ( c?.method === "get" ) {
		for ( const k in default_params ) {
			c.params 
				? c.params[ k ] = default_params[ k ]
				: c.params = {[ k ]: default_params[ k ]}
		}
	}

	return c;
});

export default {

	getMainSlider: async() => {
      const { slider } = await API.get( `/contents/domain_info/klimat` );
      const data = await API.get( `/contents/slides/${ slider }` );
      
      return data.slides.map( i => ({
         title: i.title,
         text: i.description || "",
         img: i.name,
         to: i.url
      }));
   },

	getCarbonFootprintData: async() => {
      const data = await API.get( `contents/posts/slugs/carbon-footprint` );
      return data?.posts?.[0]
   },

	getAllCategories: async() => {
      const { id } = await API.get( `/contents/top_category_by_domain/klimat` );
      const data = await API.get(`/contents/subcategories/${ id }`);
      return data?.subcategories?.categories;
   },

	getCategoryPosts: async( category_id, params = {}, cancelToken ) => {

      const { posts } = await API.get(`/contents/posts/category/${ category_id }`, {
         params: {
            page: params.page || 0,
            limit: params.limit || 10,
            promoted: params.promoted,
            unpromoted: params.unpromoted
         }, cancelToken
      });
   
      return posts;
   },

	getPostById: async( id, cancelToken ) => {
      const { posts } = await API.get(`/contents/posts/${ id }`, { cancelToken });
      return posts?.[0];
   },

	getLinkedPosts: async( id, cancelToken ) => {
      return await API.get( `/contents/linked/${ id }`, { cancelToken });
   }
}