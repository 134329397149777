export function calcWaypoints( points ) {
   const waypoints = [];
   for ( let i = 1; i < points.length; i++ ) {
      var pt0 = points[i - 1];
      var pt1 = points[i];
      var dx = pt1.x - pt0.x;
      var dy = pt1.y - pt0.y;
      for ( let j = 0; j < 100; j++ ) {
         var x = pt0.x + dx * j / 100;
         var y = pt0.y + dy * j / 100;
         waypoints.push({
            x: x,
            y: y
         });
      }
   }
 
   return waypoints;
}