export const isObject = o => toString.call( o ) === "[object Object]";

export const scrollSmoothTo = DOMElement => {

	const top = DOMElement ? DOMElement.getBoundingClientRect().top + window.scrollY - 10 : 0;

	"scrollBehavior" in document.documentElement.style
		? window.scrollTo({ top: top, behavior: 'smooth' })
		: window.scrollTop = top;
}

export const loadScript = options => {
	
	if ( !options.src ) return;
	if ( document.querySelector("#" + options.id )) return;

	const script = document.createElement('script');
	script.id  = options.id || "";
	script.src = options.src;
	script.type = options.type || "text/javascript";
	script.async = options.async;

	const place = options.place || document.body; 
	place.appendChild( script );
}

export const detectScrollToElementBottom = element => {
	const rect = element.getBoundingClientRect(); 
	return ( rect.bottom - rect.height / 4 ) < window.innerHeight;
}