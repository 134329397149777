import { useMemo, forwardRef } from "react";
import HTMLReactParser from "html-react-parser";
import useChartHelper from "hooks/useChartHelper";
import withAnimateTrigger from "hoc/withAnimateTrigger";
import BarGroups from "./components/BarGroups";

import "./bar-chart.scss";

const BarChart = forwardRef( function BarChart( props, ref ) {
	
	const { animate } = props;
	const { title, subtitle } = props;
   const { legend, data } = props;

   const colors = useMemo(() => legend?.map( item => item.color ), [ legend ])

   const { max_value, scale } = useChartHelper( data );

	return (
		<div className="bar-chart">
			
			{ title && <h3> { HTMLReactParser( title )} </h3> }
			{ subtitle && <h4> { HTMLReactParser( subtitle )} </h4> }

         { legend && !!legend?.length && 
            <div className="bar-chart-legend">
               { legend.map(({ label, color }) => (
                  <div key={ label }>
                     <div style={{ background: color }}/>
                     <span> { label } </span>
                  </div>
               ))}
            </div>
         }

			<div className="bar-chart-wrap"> 

				<div className="bar-chart-scale">
					{ scale.map( value => (
						<div key={ value }> 
							<span> { value } </span> 
						</div>
					))}
				</div>

				<div className="bar-chart-main has-scrollbar"> 
					<div ref={ ref } className="bar-chart-bars">
						{ data && !!data?.length && 
							<BarGroups 
                        data={ data }
                        colors={ colors }
                        max_value={ max_value }
                        animate={ animate }
                     />
						}
					</div>

					<div className="bar-chart-labels">
						{ !!data?.length && 
							data.map( group => {
									
                        const { label } = group;

                        return (
                           <div key={ label }> 
                              <div>
                                 { HTMLReactParser( label || "" )} 
                              </div>
                           </div>
                        )
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
})

export default withAnimateTrigger( BarChart ); 