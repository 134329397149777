import { useMemo, forwardRef } from "react";
import withAnimateTrigger from "hoc/withAnimateTrigger";
import AnimatedValue from "components/general/AnimatedValue";
import HTMLReactParser from "html-react-parser";

import "./pie-chart.scss";

const PieChart = forwardRef( function PieChart({ data, title, animate }, ref ) {

	const total = useMemo(() => {
		return data 
			? data.reduce(( total, current ) => current.value + total, 0 )
			: null
	}, [ data ])

	const values = useMemo(() => {
		
		if ( !total ) return;
		
		const values = data.map( item => {
			
			const percent = +( item.value / total * 100 ).toFixed(1);
			
			return { 
				percent: percent,
				deg: 360 / 100 * percent, 
				...item
			}
		});
		
		let hold_deg = 0;
		values.forEach( item => {
			item.hold_deg = hold_deg;
			hold_deg += item.deg;
		});

		return values;
	}, [ total ])

	return (
		<div className="pie-chart">

			{ title && <h3> { HTMLReactParser( title )} </h3> }

			<div className="pie-chart__labels">
				{ values && !!values.length &&
					values.map(({ percent, value, color, label }) => (
						<div className="flex" key={ value }> 
							
							<div style={{ background: color }}/> 

							<div> 
								<span> { HTMLReactParser( label || "" )} - { percent }% </span>
								<strong> <AnimatedValue value={ value } animate={ animate }/> </strong>
							</div>
						</div>
					))
				}
			</div>

			<div ref={ ref } className="pie"> 
				{ values && !!values.length && 
					values.map(({ deg, hold_deg, color }) => (
						<div 
							key={ deg } 
							className={`pie__hold ${ deg >= 180 ? "rest" : "" }`}
							style={{ transform: deg < 180 ? `rotate(${ hold_deg }deg)` : "" }}
						> 
							<div 
								className="pie__slice" 
								style={{ background: color, transform: animate ? `rotate(${ deg }deg)` : `rotate(0deg)` }}
							/>
						</div>
					))
				}
			</div>
		</div>
	)
})

export default withAnimateTrigger( PieChart ); 