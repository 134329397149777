import { useRef, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Paths from "router/Paths";
import { useDataContext } from "context/DataContext";
import useWindowClick from "hooks/useWindowClick";

import { AngleDownIcon, MenuIcon, CloseIcon } from "svg/icons";
import "./navigation.scss";

const items = [
	{
		label: "Aktualności",
		to: Paths.News
	},
	{
		label: "Ślad węglowy",
		to: Paths.CarbonFootprint
	},
	{
		label: "Wiedza",
	},
	{
		label: "Projekty",
	},
	{
		label: "Działania",
	},
	{
		label: "Dotacje"
	},
	{
		label: "Lista artykułów",
		to: Paths.Articles
	}
]

export default function Navigation() {

   const nav_ref = useRef();
	const { menu_posts } = useDataContext();

	const [ chosen, setChosen ] = useState( null );
	const [ mobile_menu_open, setMobileMenuOpen ] = useState( false );

	const posts = menu_posts[ chosen ];

	const toggleMobileMenu = () => {
		document.body.style.overflow = mobile_menu_open ? "" : "hidden";
		setMobileMenuOpen( o => !o );
	}

	useWindowClick( e => { !nav_ref.current.contains( e.target ) && setChosen( null )});

	useEffect(() => {
		return () => document.body.style.overflow = "";
	}, [])

	return (
		<>
			<div ref={ nav_ref } className={`navigation ${ mobile_menu_open ? "open" : "" }`}> 
				<nav>

					<button 
						typ="button"
						className="navigation-mobile-btn"
						onClick={ toggleMobileMenu }
					>
						<CloseIcon/>
						<span className="d-none"> toggle mobile menu </span>
					</button>

					<ul>
						{ items.map(({ label, to }, i ) => to ? (
							<li key={ to }>
								<NavLink
									to={ to }
									className={({ isActive }) => isActive ? "active" : ""}	
								>
									{ label }
								</NavLink>
							</li>
						) : (
							menu_posts[ label ]?.length === 1 ? (
								<li key={ i }>
									<NavLink
										to={ Paths.Post( menu_posts[ label ][0].id )}
										className={({ isActive }) => isActive ? "active" : "" }
										onClick={() => {
											document.body.style.overflow = "";
											setMobileMenuOpen( false );
										}}
									>
										{ label }
									</NavLink>
								</li>
							) : (
							<li key={ i }>
								<span 
									href={`#${ label }`}
									className={ chosen === label ? "posts-active" : "" }
									onClick={() => {
										setChosen( chosen === label ? null : label )
									}}
								>
									{ label }

									{ menu_posts[ label ]?.length > 1 && <AngleDownIcon/> }

									<ul>
										{ !!menu_posts[ label ]?.length &&
											menu_posts[ label ].map( item => (
												<li key={ item.id }>
													<NavLink
														to={ Paths.Post( item.id )}
														className={({ isActive }) => isActive ? "active" : ""}	
														onClick={() => { 
															document.body.style.overflow = ""
															setMobileMenuOpen( false )
														}}
													>
														{ item.title }
													</NavLink>
												</li>
											))
										}
									</ul>
								</span>
							</li>
						)))}
					</ul>
				</nav>

				{ posts && !!posts.length &&
					<div className="navigation-posts">
						<div className="navigation-posts-items has-scrollbar"> 
							{ posts.map( item => (
								<Link 
									key={ item.id } 
									to={ Paths.Post( item.id )}
									onClick={() => setChosen( null )}	
								>

									<div style={{ backgroundImage: `url(${ item.image })`}}/>

									{ item.title }
								</Link>
							))}
						</div>
					</div>
				}

				<button 
					typ="button"
					className="navigation-mobile-btn"
					onClick={ toggleMobileMenu }
				>
					<MenuIcon/>
					<span className="d-none"> toggle mobile menu </span>
				</button>
			</div>
		</>
	)
}