import { useState, useEffect, useRef } from "react";
import SwipeableBox from "components/general/SwipeableBox";
import Button from "components/buttons/Button";
import Loader from "components/general/Loader";
import Arrows from "components/general/Arrows";

import "./carousel.scss";

export default function Carousel( props ) {
	
	const carousel_items_ref = useRef();
	const { heading, items, className = "", more = {}, ItemComponent, onItemClick } = props;

	const [ carousel_left, setCarouselLeft ] = useState(0);
	const [ show_arrows, setShowArrows ] = useState( false );

	const getItemSizes = () => {

		const item = carousel_items_ref.current.children?.[0];
		const item_width = item.offsetWidth || 0;
		const gap = 25;

		return { width: item_width, gap: gap, widthWithGap: item_width + gap };
	}

	const getLastItemLeft = () => {
		const sizes = getItemSizes();
		return sizes.widthWithGap * items.length - sizes.gap;
	}

	const moveItems = action => {

		switch ( action ) {
			case "prev":
				if ( carousel_left >= 0 ) return;
				setCarouselLeft( carousel_left + getItemSizes().widthWithGap );
				break;

			case "next":
				if ( getLastItemLeft() <= carousel_items_ref.current.offsetWidth - carousel_left ) return;
				setCarouselLeft( carousel_left - getItemSizes().widthWithGap );
				break;
		}
	}

	const arrowsCheck = () => {
		setCarouselLeft(0);
		setShowArrows( getLastItemLeft() > carousel_items_ref.current.offsetWidth );
	}

	useEffect(() => {

		if ( !items || !items?.length ) return;

		arrowsCheck();

		window.addEventListener( "resize", arrowsCheck );
		return () => window.removeEventListener( "resize", arrowsCheck );
	}, [ items ])

	useEffect(() => { setCarouselLeft(0)}, [ items ])

	if ( items && !items.length ) return null;

	return (
		<div className={`carousel ${ className }`}>

			<div className="content-container">

				<div 
					className="carousel-top" 
					style={{ alignItems: more.to && more.label ? "flex-start" : "center" }}
				>
					
					{ heading && <h2> { heading } </h2> }

					<div>

						{ more.to && more.label &&
							<Button to={ more.to }> { more.label } </Button>
						}

						{ show_arrows &&
							<Arrows onClick={ moveItems }/>
						}
					</div>
				
				</div>
			</div>
			
			{ !items && <Loader extra_classes={ className?.search("green") !== -1 ? "white" : "" }/> }

			<div className="carousel-wrap"> 
				<SwipeableBox 
					ref={ carousel_items_ref }
					className="carousel-items" 
					style={{ left: carousel_left }} 
					onSwipeLeft={() => moveItems( "next" )}
					onSwipeRight={() => moveItems( "prev" )}	
				> 
					{ items && !!items.length && 
						items.map(( item, i ) => (
							<ItemComponent  
								key={ item?.id || i }
								onClick={ e => onItemClick && onItemClick( item, i, e )}
								{ ...item }
							/>
						))
					}
				</SwipeableBox>
			</div>
		</div>
	)
}

