import "./bar-groups.scss";

export default function BarGroups({ data, colors, animate, max_value }) {
	
	return (
		<>
			{ data && !!data?.length &&  
				data.map(({ values }, i ) => {
               return (
                  <div key={ i } className="bar-group">
                     { values?.map(( value, j ) => {

                        const width = 100 / values.length + "%";
                        const value_height = ( value / max_value  * 100 ) + "%";
                        const color= colors?.[ j ];
                        
                        return (
                           <div 
                              key={ value + j }
                              className="bar" 
                              style={{ width }}
                           >
                              <div 
                                 className="bar-value-view"
                                 title={ value }
                                 style={{ height: animate ? value_height : 0, background: color }}
                              />
                           </div>
                        )
                     })}
                  </div>
               ) 
				})
			}
		</>
	)
}

