import { useEffect, useRef, useState } from "react";
import useWindowScroll from "hooks/useWindowScroll";
import { detectScrollToElementBottom } from "extra/functions";


export default function withAnimateTrigger( Component ) {
	return function( props ) {

		const element_ref = useRef();
		const is_scrolled_to = useWindowScroll( checkIsScrolledTo );

      const [ animate, setAnimate ] = useState( false );

      function checkIsScrolledTo() {
			const element = element_ref?.current;
			return element ? detectScrollToElementBottom( element ) : false;
		}

      useEffect(() => {
         if ( !is_scrolled_to && !animate ) return;
         setAnimate( true );
      }, [ is_scrolled_to ]);

		return (
			<Component 
				{...props } 
				ref={ element_ref }
				animate={ animate } 
			/>
		)
	}
}

